<script setup lang="ts">
const { t } = useI18n({})

const { recentProducts } = useRecentProducts()
</script>

<template>
  <div
    v-if="recentProducts.length"
    class="container mx-auto mb-40 w-screen px-2"
  >
    <div class="flex-center mt-40 flex flex-col">
      <div class="title mb-4 text-center text-2xl font-bold">
        {{ t('home.recent_products') }}
      </div>

      <div v-auto-animate class="grid md:grid-cols-2 lg:grid-cols-3 w-full gap-4">
        <RecentProductItem
          v-for="product in recentProducts"
          :key="product.id"
          :product="product"
        />
      </div>
    </div>
  </div>
</template>
