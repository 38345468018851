import type { Product, VariantColor } from '~/types/product.type'

export interface RecentProduct {
  id: number
  colors: VariantColor[]
  image: string
  title: string

}
export function useRecentProducts() {
  const recentProducts = useCookie<RecentProduct[]>('recent-products', {
    default: () => [],
  })

  const visitProduct = (product: Product) => {
    const colors = product.variants.map(x => x.color).reduce((acc: VariantColor[], curr) => {
      if (!acc.find(x => x?.title === curr?.title) && curr?.code)
        acc.push(curr)
      return acc
    }, [])

    const recentProduct: RecentProduct = {
      id: product.id,
      colors,
      image: product.images[0]?.url,
      title: product.title,
    }
    const index = recentProducts.value.findIndex(p => p.id === product.id)
    if (index !== -1)
      recentProducts.value.splice(index, 1)

    recentProducts.value.unshift(recentProduct)
    if (recentProducts.value.length > 3)
      recentProducts.value.pop()
  }

  return {
    recentProducts,
    visitProduct,
  }
}
